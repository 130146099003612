




























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  data: () => ({
    termDialog: true,
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['employee']),
  },
  methods: {
    ...mapActions('EmployeesEmployeeModule', ['updateEmployee']),
    agreeTerms() {
      this.updateEmployee({
        ref: this.employee.ref,
        agreeTerms: true,
      }).then(() => {
        this.termDialog = false;
        setTimeout(() => {
          location.reload();
        }, 500);
      });
    },
  },
});
