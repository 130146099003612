














































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import Snackbar from '@/components/ui/Snackbar.vue';
import Analytics from '@/utils/analytics';
import weekdayMap from '@/utils/weekdayMap';
import { DateFormat } from '@/types/dateFormat';
import { Commute } from '@/types/commute';
import { dialogNames } from '@/types/dialogNames';

export default Vue.extend({
  components: {
    Snackbar,
  },
  props: {
    schedulesToWork: {
      type: Object,
      required: true,
    },
    schedulesFromWork: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showCompletionToWorkDialog: false,
    showCompletionFromWorkDialog: false,
    completionToWorkDialog: false,
    completionFromWorkDialog: false,
    result: null,
    now: DateTime.local(),
    weekday: -1,
    weekdayMap: weekdayMap,
    required: (v: any) => !!v || '選択してください',
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['employee']),
    ...mapGetters('EmployeesEmployeeCarpoolLogModule', [
      'todayEmployeeCarpoolToWorkLog',
      'todayEmployeeCarpoolFromWorkLog',
    ]),
    ...mapGetters('EmployeesCarpoolLogModule', [
      'todayCarpoolToWorkLog',
      'todayCarpoolFromWorkLog',
    ]),
  },
  methods: {
    ...mapActions('EmployeesEmployeeCarpoolLogModule', [
      'listTodayEmployeeCarpoolLogs',
      'createEmployeeCarpoolLog',
    ]),
    ...mapActions('EmployeesCarpoolLogModule', [
      'getCarpoolLog',
      'updateCarpoolLog',
    ]),
    refs(): any {
      return this.$refs;
    },
    saveCompletionLog(commute: string) {
      if (!this.refs().completionForm.validate()) {
        return;
      }
      this.createEmployeeCarpoolLog({
        commute: commute,
        date: this.now.toFormat(DateFormat.yyyyMMdd),
        weekday: this.weekday,
        employeeRef: this.employee.ref,
        employeeType: this.employee.type,
        result: this.result,
      }).then(() => {
        this.refs().snackbarRef.showSnackbar({
          color: 'success',
          text: '通勤結果を決定しました。',
        });
        this.completionToWorkDialog = false;
        this.completionFromWorkDialog = false;
      });
      this.updateCarpoolLog({
        log:
          commute === Commute.toWork
            ? this.todayCarpoolToWorkLog
            : this.todayCarpoolFromWorkLog,
        employeeId: this.employee.ref.id,
        result: this.result,
      });
      Analytics.logDialogView(dialogNames.completion);
    },
  },
  created() {
    this.listTodayEmployeeCarpoolLogs({
      date: this.now.toFormat(DateFormat.yyyyMMdd),
      employeeRef: this.employee.ref,
    }).then(() => {
      this.weekday = this.now.weekday;
      if (this.schedulesToWork[this.weekday]) {
        if (
          this.now >
          DateTime.fromFormat(
            this.now.toFormat(DateFormat.yyyyMMdd) +
              ' ' +
              this.employee.beginTimes[this.weekday],
            'yyyy-MM-dd HH:mm'
          )
        ) {
          this.showCompletionToWorkDialog = true;
        }
      }
      if (this.schedulesFromWork[this.weekday]) {
        if (
          this.now >
          DateTime.fromFormat(
            this.now.toFormat(DateFormat.yyyyMMdd) +
              ' ' +
              this.employee.finishTimes[this.weekday],
            'yyyy-MM-dd HH:mm'
          )
        ) {
          this.showCompletionFromWorkDialog = true;
        }
      }
    });
    this.getCarpoolLog({
      organizationId: this.employee.organizationRef.id,
      date: this.now.toFormat(DateFormat.yyyyMMdd),
    });
  },
});
