var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.schedulesToWork && _vm.schedulesFromWork)?_c('CompletionDialog',{attrs:{"schedulesToWork":_vm.schedulesToWork,"schedulesFromWork":_vm.schedulesFromWork}}):_vm._e(),_vm._l((_vm.weekdays),function(weekday,i){return _c('div',{key:i},[_c('h3',{staticClass:"my-4",class:{
        'primary--text': weekday === 6,
        'error--text': weekday === 7,
      }},[_vm._v(" "+_vm._s(_vm.dates[i])+"（"+_vm._s(_vm.weekdayMap[weekday])+"） ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.schedulesToWork[weekday])?_c('v-card',{attrs:{"disabled":!_vm.schedulesToWork[weekday]}},[_c('v-card-title',[_c('span',[_vm._v(" 出勤 ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","text":""},on:{"click":function($event){_vm.absenceWeekday = weekday;
                _vm.absenceDailog = true;}}},[_vm._v(" 欠勤/遅刻 ")])],1),_c('v-card-text',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" "+_vm._s(_vm.schedulesToWork[weekday].clients.length + 1)+"名 ")],1),_c('v-card-actions',[_c('v-btn',{attrs:{"to":{
                path: '/route-to-work',
                query: {
                  weekday: weekday,
                  date: _vm.dates[i],
                },
              },"color":"primary","outlined":""}},[_vm._v(" ルートをみる ")]),_c('v-spacer')],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.schedulesFromWork[weekday])?_c('v-card',{attrs:{"disabled":!_vm.schedulesFromWork[weekday]}},[_c('v-card-title',[_c('span',[_vm._v(" 退勤 ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","text":""},on:{"click":function($event){_vm.overtimeWeekday = weekday;
                _vm.overtimeDailog = true;}}},[_vm._v(" 早退/残業 ")])],1),_c('v-card-text',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" "+_vm._s(_vm.schedulesFromWork[weekday].clients.length + 1)+"名 ")],1),_c('v-card-actions',[_c('v-btn',{attrs:{"to":{
                path: '/route-from-work',
                query: {
                  weekday: weekday,
                  date: _vm.dates[i],
                },
              },"color":"success","outlined":""}},[_vm._v(" ルートをみる ")]),_c('v-spacer')],1)],1):_vm._e()],1)],1),(!_vm.schedulesToWork[weekday] && !_vm.schedulesFromWork[weekday])?_c('v-card',[_c('v-card-text',{staticClass:"text-center"},[_vm._v(" お休み ")])],1):_vm._e()],1)}),(_vm.absenceWeekday !== null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"512"},model:{value:(_vm.absenceDailog),callback:function ($$v) {_vm.absenceDailog=$$v},expression:"absenceDailog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 欠勤/遅刻の連絡 ")]),_c('v-card-text',[(
            _vm.schedulesToWork[_vm.absenceWeekday].server.ref.id !== _vm.employee.ref.id
          )?_c('div',[_vm._v(" 急遽、会社を休む・遅刻する場合、当日の運転者にご連絡ください。 "),_c('br'),_vm._v(" "+_vm._s(_vm.schedulesToWork[_vm.absenceWeekday].server.displayName)+" さん "),_c('v-btn',{attrs:{"icon":"","href":'tel:' + _vm.schedulesToWork[_vm.absenceWeekday].server.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + _vm.schedulesToWork[_vm.absenceWeekday].server.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + _vm.schedulesToWork[_vm.absenceWeekday].server.email}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_c('br')],1):_c('div',[_vm._v(" 急遽、会社を休む・遅刻する場合、当日の同乗者全員にご連絡ください。 "),_c('br'),_vm._l((_vm.schedulesToWork[_vm.absenceWeekday].clients),function(client,i){return _c('div',{key:i},[(client.ref.id !== _vm.employee.ref.id)?_c('div',[_vm._v(" "+_vm._s(client.displayName)+" さん "),_c('v-btn',{attrs:{"icon":"","href":'tel:' + client.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + client.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + client.email}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_c('br')],1):_vm._e()])})],2)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.absenceWeekday = null;
            _vm.absenceDailog = false;}}},[_vm._v(" 閉じる ")])],1)],1)],1):_vm._e(),(_vm.overtimeWeekday !== null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"512"},model:{value:(_vm.overtimeDailog),callback:function ($$v) {_vm.overtimeDailog=$$v},expression:"overtimeDailog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 早退・残業の連絡 ")]),_c('v-card-text',[(
            _vm.schedulesFromWork[_vm.overtimeWeekday].server.ref.id !==
              _vm.employee.ref.id
          )?_c('div',[_vm._v(" 急遽、早退・残業で相乗りしない場合、当日の運転者にご連絡ください。 "),_c('br'),_vm._v(" "+_vm._s(_vm.schedulesFromWork[_vm.overtimeWeekday].server.displayName)+" さん "),_c('v-btn',{attrs:{"icon":"","href":'tel:' + _vm.schedulesFromWork[_vm.overtimeWeekday].server.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + _vm.schedulesFromWork[_vm.overtimeWeekday].server.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + _vm.schedulesFromWork[_vm.overtimeWeekday].server.email}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_c('br')],1):_c('div',[_vm._v(" 急遽、早退・残業で運転できない場合、当日の同乗者全員にご連絡ください。 "),_c('br'),_vm._l((_vm.schedulesFromWork[_vm.overtimeWeekday].clients),function(client,i){return _c('div',{key:i},[(client.ref.id !== _vm.employee.ref.id)?_c('div',[_vm._v(" "+_vm._s(client.displayName)+" さん "),_c('v-btn',{attrs:{"icon":"","href":'tel:' + client.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + client.phoneNumber}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + client.email}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1),_c('br')],1):_vm._e()])})],2)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.overtimeWeekday = null;
            _vm.overtimeDailog = false;}}},[_vm._v(" 閉じる ")])],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }