

























































































































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import firebase from '@/firebase';
import CompletionDialog from '@/components/employee/CompletionDialog.vue';
import weekdayMap from '@/utils/weekdayMap';

export default Vue.extend({
  components: {
    CompletionDialog,
  },
  data: () => ({
    absenceDailog: false,
    absenceWeekday: null,
    overtimeDailog: false,
    overtimeWeekday: null,
    schedulesToWork: null,
    schedulesFromWork: null,
    weekdays: [] as number[],
    weekdayMap: weekdayMap,
    dates: [] as string[],
  }),
  computed: {
    ...mapGetters('EmployeesCpFromWorkOutputModule', [
      'findFeasiblePathFromWork',
      'findFeasiblePathFromWorkByServer',
      'findFeasiblePathFromWorkByClient',
    ]),
    ...mapGetters('EmployeesCpToWorkOutputModule', [
      'findFeasiblePathToWork',
      'findFeasiblePathToWorkByServer',
      'findFeasiblePathToWorkByClient',
    ]),
    ...mapGetters('EmployeesEmployeeModule', ['employee', 'findEmployeeById']),
  },
  methods: {
    ...mapActions('EmployeesCpFromWorkOutputModule', ['getCpFromWorkOutput']),
    ...mapActions('EmployeesCpToWorkOutputModule', ['getCpToWorkOutput']),
    ...mapActions('EmployeesEmployeeModule', ['listEmployees', 'getEmployee']),
    ...mapActions('EmployeesOrganizationModule', ['getOrganization']),
    ...mapActions('EmployeesMessagingModule', ['addToken']),
    refs(): any {
      return this.$refs;
    },
    initWeeklySchedules() {
      const weekdays: number[] = [];
      const dates: string[] = [];
      const today: DateTime = DateTime.local();
      weekdays.push(today.weekday);
      dates.push(today.toFormat('M月d日'));
      Array(6)
        .fill(0)
        .forEach((_, i) => {
          weekdays.push((weekdays[i] % 7) + 1);
          dates.push(today.plus({ days: i + 1 }).toFormat('M月d日'));
        });
      this.weekdays = weekdays;
      this.dates = dates;

      const schedulesToWork: any = {};
      weekdays.forEach((weekday: number) => {
        if (!this.findFeasiblePathToWork(weekday)) {
          schedulesToWork[weekday] = null;
          return;
        }
        if (this.employee.type === 'server') {
          schedulesToWork[weekday] = this.findFeasiblePathToWorkByServer(
            weekday,
            this.employee.ref.id
          );
        } else if (this.employee.type === 'client') {
          schedulesToWork[weekday] = this.findFeasiblePathToWorkByClient(
            weekday,
            this.employee.ref.id
          );
        }
        if (!schedulesToWork[weekday]) {
          return;
        }
        schedulesToWork[weekday].server = this.findEmployeeById(
          schedulesToWork[weekday].server
        );
        const clients: any = [];
        schedulesToWork[weekday].clients.forEach((client: string) => {
          clients.push(this.findEmployeeById(client));
        });
        schedulesToWork[weekday].clients = clients;
      });
      this.schedulesToWork = schedulesToWork;

      const schedulesFromWork: any = {};
      weekdays.forEach((weekday: number) => {
        if (!this.findFeasiblePathFromWork(weekday)) {
          schedulesFromWork[weekday] = null;
          return;
        }
        if (this.employee.type === 'server') {
          schedulesFromWork[weekday] = this.findFeasiblePathFromWorkByServer(
            weekday,
            this.employee.ref.id
          );
        } else if (this.employee.type === 'client') {
          schedulesFromWork[weekday] = this.findFeasiblePathFromWorkByClient(
            weekday,
            this.employee.ref.id
          );
        }
        if (!schedulesFromWork[weekday]) {
          return;
        }
        schedulesFromWork[weekday].server = this.findEmployeeById(
          schedulesFromWork[weekday].server
        );
        const clients: any = [];
        schedulesFromWork[weekday].clients.forEach((client: string) => {
          clients.push(this.findEmployeeById(client));
        });
        schedulesFromWork[weekday].clients = clients;
      });
      this.schedulesFromWork = schedulesFromWork;
    },
  },
  created() {
    this.getEmployee({
      id: (firebase.auth().currentUser || {}).uid,
    }).then((employee) => {
      this.listEmployees({
        organizationRef: employee.organizationRef,
      }).then(() => {
        this.getCpToWorkOutput({
          id: employee.organizationRef.id,
        }).then(() => {
          this.getCpFromWorkOutput({
            id: employee.organizationRef.id,
          }).then(() => {
            this.initWeeklySchedules();
          });
        });
      });
      // Token を送信
      this.getOrganization({
        id: employee.organizationRef.id,
      }).then((organization) => {
        this.addToken(organization);
      });
    });
  },
});
