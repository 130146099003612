























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Schedule from '@/components/employee/Schedule.vue';
import Profile from '@/components/employee/Profile.vue';
import Terms from '@/components/employee/Terms.vue';

export default Vue.extend({
  components: {
    Schedule,
    Profile,
    Terms,
  },
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['employee']),
    ...mapGetters('UiAppModule', ['uiApp']),
  },
  methods: {
    ...mapActions('UiAppModule', ['setUiAppShowTerms']),
    refs(): any {
      return this.$refs;
    },
  },
  watch: {
    employee() {
      if (!this.employee.agreeTerms) {
        this.setUiAppShowTerms();
        return;
      }
      if (!this.employee.activated) {
        this.refs().profileRef.openProfileDialog();
      }
    },
  },
});
