









































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Loader } from '@googlemaps/js-api-loader';
import firebase from '@/firebase';
import weekdayMap from '@/utils/weekdayMap';
import Analytics from '@/utils/analytics';
import NearestRoad from '@/utils/nearestRoad';
import { dialogNames } from '@/types/dialogNames';
import MsgCache from '@/utils/msgCache';

export default Vue.extend({
  data: () => ({
    currentUser: firebase.auth().currentUser,
    profile: {
      displayName: '',
      email: '',
      newEmail: '',
      photoURL: '',
      photoFile: null,
      password: '',
      newPassword: '',
    },
    isDefaultPhoto: true,
    initedMap: false,
    disabledMap: false,
    dialog: {
      profile: false,
      email: false,
      password: false,
      system: false,
    },
    required: (v: any) => !!v || 'ご入力ください',
    capacityRule: (v: number) => v >= 2 && v <= 13,
    maxDrivingTimeRule: (v: number) => v >= 60 && v <= 120,
    emailRules: [
      (v: any) => !!v || 'メールアドレスをご入力ください',
      (v: any) => /.+@.+\..+/.test(v) || '有効なメールアドレスをご入力ください',
    ],
    profilePhotoRules: [(v: any) => !v || v.size < 2000000 || '2MB以下'],
    weekdayMap: weekdayMap,
    msgCache: MsgCache,
  }),
  computed: {
    ...mapGetters('EmployeesEmployeeModule', ['employee']),
    ...mapGetters('EmployeesOrganizationModule', ['organization']),
  },
  methods: {
    ...mapActions('EmployeesEmployeeModule', ['updateEmployee']),
    ...mapActions('StorageProfilePhotoModule', [
      'createProfilePhoto',
      'getProfilePhoto',
    ]),
    refs(): any {
      return this.$refs;
    },
    initMapForGeoPoint(lat: number, lng: number) {
      if (this.initedMap) return;
      const loader = new Loader({
        apiKey: JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)['apiKey'],
        version: 'weekly',
      });
      loader.load().then(() => {
        const mapElement = document.getElementById('profileMap');
        if (!mapElement) {
          this.disabledMap = true;
          return;
        }
        const map = new (window as any).google.maps.Map(
          mapElement as HTMLElement,
          {
            center: { lat: lat, lng: lng },
            zoom: 15,
          }
        );
        let infoWindow = new (window as any).google.maps.InfoWindow({
          content: this.employee.geoPoint
            ? '出発・到着地点'
            : 'タップして位置をご指定ください。',
          position: { lat: lat, lng: lng },
        });
        infoWindow.open(map);
        map.addListener('click', (mapsMouseEvent: any) => {
          (this.employee.geoPoint = new firebase.firestore.GeoPoint(
            mapsMouseEvent.latLng.lat(),
            mapsMouseEvent.latLng.lng()
          )),
            infoWindow.close();
          infoWindow = new (window as any).google.maps.InfoWindow({
            position: mapsMouseEvent.latLng,
          });
          infoWindow.setContent(JSON.stringify('出発・到着地点', null, 2));
          infoWindow.open(map);
        });
        this.initedMap = true;
      });
    },
    openProfileDialog() {
      if (this.employee.geoPoint) {
        this.initMapForGeoPoint(
          this.employee.geoPoint.latitude,
          this.employee.geoPoint.longitude
        );
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.initMapForGeoPoint(
            position.coords.latitude,
            position.coords.longitude
          );
        });
      } else {
        this.initMapForGeoPoint(
          this.organization.geoPoint.latitude,
          this.organization.geoPoint.longitude
        );
      }
      this.dialog.profile = true;
      Analytics.logDialogView(dialogNames.profile);
    },
    saveProfileAndEmployee() {
      if (!this.currentUser) return;
      this.currentUser.updateProfile(this.profile).finally(() => {
        this.employee.email = this.profile.email;
        this.employee.displayName = this.profile.displayName;
        this.employee.photoURL = this.profile.photoURL;
        this.employee.capacity = Number(this.employee.capacity);
        this.employee.maxDrivingTime *= 60;
        this.employee.activated = true;
        // 緯度経度を道路上に補正
        NearestRoad.getLocation(
          this.employee.geoPoint.latitude,
          this.employee.geoPoint.longitude
        ).then((point) => {
          if (point) {
            this.employee.geoPoint = new firebase.firestore.GeoPoint(
              point.latitude,
              point.longitude
            );
          }
          this.updateEmployee(this.employee).then(() => {
            this.dialog.profile = false;
            location.reload();
          });
        });
      });
    },
    saveProfileAndEmployeeWithPhoto() {
      if (!this.currentUser || !this.currentUser.providerData[0]) {
        return;
      }
      if (!this.refs().employeeForm.validate()) {
        return;
      }
      if (!this.employee.geoPoint) {
        alert('出発・到着地点をご指定ください。');
        return;
      }
      const currentUserId = this.currentUser.uid;
      if (!this.profile.photoFile) {
        if (this.isDefaultPhoto) {
          this.profile.photoURL = '';
        }
        this.saveProfileAndEmployee();
      } else {
        this.createProfilePhoto({
          file: this.profile.photoFile,
          name: currentUserId,
        }).then((filename) => {
          this.profile.photoURL = filename;
          this.getProfilePhoto({
            name: currentUserId,
          }).then((url) => {
            this.profile.photoURL = url;
            this.saveProfileAndEmployee();
          });
        });
      }
    },
    setBiginTime(weekday: number, time: string | null) {
      this.employee.beginTimes[weekday] = time ? null : '09:00';
    },
    setFinishTime(weekday: number, time: string | null) {
      this.employee.finishTimes[weekday] = time ? null : '18:00';
    },
    getCredential() {
      return firebase.auth.EmailAuthProvider.credential(
        this.profile.email,
        this.profile.password
      );
    },
    updateEmail() {
      if (!this.refs().emailForm.validate()) {
        return;
      }
      if (!this.currentUser) return;
      this.currentUser
        .reauthenticateWithCredential(this.getCredential())
        .then(() => {
          if (!this.currentUser) return;
          this.currentUser
            .updateEmail(this.profile.newEmail)
            .then(() => {
              this.profile.email = this.profile.newEmail;
              this.profile.newEmail = '';
            })
            .catch((e) => {
              console.error(e.message);
            })
            .finally(() => {
              this.employee.email = this.profile.email;
              this.updateEmployee(this.employee).then(() => {
                this.dialog.email = false;
              });
            });
        });
      Analytics.logDialogView(dialogNames.changeEmail);
    },
    updatePassword() {
      if (!this.refs().passwordForm.validate()) {
        return;
      }
      if (!this.currentUser) return;
      this.currentUser
        .reauthenticateWithCredential(this.getCredential())
        .then(() => {
          if (!this.currentUser) return;
          this.currentUser
            .updatePassword(this.profile.newPassword)
            .then(() => {
              this.profile.password = this.profile.newPassword;
              this.profile.newPassword = '';
            })
            .catch((e) => {
              console.error(e.message);
            })
            .finally(() => {
              this.dialog.password = false;
            });
        });
      Analytics.logDialogView(dialogNames.resetPassword);
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
          localStorage.clear();
          sessionStorage.clear();
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    if (!this.currentUser || !this.currentUser.providerData[0]) return;
    this.profile.email = this.currentUser.providerData[0].email || '';
    this.profile.displayName =
      this.currentUser.providerData[0].displayName || '';
    this.profile.photoURL = require('../../assets/profile_photo.jpg');
    if (this.currentUser.providerData[0].photoURL) {
      this.getProfilePhoto({
        name: this.currentUser.uid,
      }).then((url) => {
        this.profile.photoURL = url;
        this.isDefaultPhoto = false;
      });
    }
  },
  watch: {
    employee() {
      this.employee.maxDrivingTime /= 60;
    },
  },
});
