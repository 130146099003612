class NearestRoad {
  async getLocation(lat: number, lng: number) {
    return await fetch(
      'https://roads.googleapis.com/v1/nearestRoads?key=' +
        JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)['apiKey'] +
        '&points=' +
        lat +
        ',' +
        lng
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.snappedPoints) {
          return null;
        }
        return data.snappedPoints[0].location;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}

export default new NearestRoad();
